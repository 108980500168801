<template>
  <div
    class="filter-container block mt-2 pb-4"
    style="overflow-x: auto"
  >
    <DateRangePicker
      v-for="field in dates"
      :key="field"
      class="pill inline-block mb-1"
      :start="startValues[field]"
      :end="endValues[field]"
      :placeholder="$t(`${organisationPrefix}hzba.buttons.${field}Filter`)"
      @update:range="dateFilterChanged(field, $event)"
      @update:single="dateFilterChanged(field, $event)"
    />
    <div
      v-for="option in filterOptions"
      :key="option.id"
      class="inline-block mb-1"
    >
      <div
        v-if="!option.onlyShowWhenOthersActive || resetButtonActive"
        class="pill"
        :class="option.resetOtherFilters && resetButtonActive ? 'resetter' : (selectedFilters && selectedFilters.find(el => el.id === option.id) ? 'active' : '')"
        @click="() => toggleOption(option)"
      >
        {{ translatedHzbaStatus(option.id,t) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {translatedHzbaStatus} from "@/models/ba/interfaces/IBestandsaufnahme";
import DateRangePicker from "@/components/Base/DateRangePicker.vue";
import { useI18n } from "vue-i18n";
import { computed, ComputedRef, reactive, ref, watch } from "vue";
import { DateFieldTypes, DateFilters } from "@/store/store.types";
import User from "@/models/user";
export default {
  name: "Filters",
  components: {
    DateRangePicker
  },
  props: {
    filterOptions: {
      type: Array,
      required: true
    },
    selectedFilters: {
      type: Array,
      required: true
    }
  },
  emits: ["update:selectedFilters", "update:dateFilter"],
  setup(props: any, { emit } : { emit: any }) {
    const { t } = useI18n({ useScope: "global" });

    const dates: DateFieldTypes[] = ["begehungsdatum", "sichtungsdatum"];
    const dateFilters = ref<DateFilters>([]);
    const startValues = reactive<Record<string, string>>({});
    const endValues = reactive<Record<string, string>>({});

    const resetButtonActive = computed(() => {
      return props.selectedFilters.filter((filter: any) => !filter.resetOtherFilters).length > 0 || dateFilters.value.length > 0;
    });

    const organisationPrefix: ComputedRef<string> = computed(() => {
      const currentUser: User | null = User.query().first();

      return currentUser ? currentUser.organisationPrefix : "";
    });

    const resetDateFilter = () => {
      dateFilters.value = [];
      Object.keys(startValues).forEach((key) => {
        startValues[key] = "";
        endValues[key] = "";
      });
    }

    const toggleOption = (option: any) => {
      const copiedFilter = JSON.parse(JSON.stringify(props.selectedFilters));

      const index = copiedFilter.findIndex((el: any) => el.id === option.id)

      if(option.resetOtherFilters && resetButtonActive.value) {
        resetDateFilter();
      }

      if (index >= 0) {
        copiedFilter.splice(index, 1);
      } else {

        if (option.resetOtherFilters) {
          while (copiedFilter.length > 0) {
            copiedFilter.pop();
          }
        }

        for (let i = copiedFilter.length-1; i >= 0; i--) {
          const item = copiedFilter[i];
          if (item.resetSelfWhenOthersActive) {
            copiedFilter.splice(i, 1)
          }
        }

        copiedFilter.push(option);
      }

      emit('update:selectedFilters', copiedFilter);
    }

    const dateFilterChanged = (field: DateFieldTypes, filter: string | string[]) => {
        if (Array.isArray(filter)) {
          startValues[field] = filter[0];
          endValues[field] = filter[1];
        } else {
          startValues[field] = filter;
          endValues[field] = "";
        }

        const existingFilters = dateFilters.value.filter(el => el.option !== field);
        if (filter !== "") {
          existingFilters.push({ option: field, value: filter });    
        }
        dateFilters.value = existingFilters;     
    }

    watch(dateFilters, (value) => {
      emit("update:dateFilter", value);
    });

    return {
      toggleOption,
      translatedHzbaStatus,
      t,
      dateFilterChanged,
      dates,
      startValues,
      endValues,
      resetButtonActive,
      organisationPrefix
    }
  }
}
</script>

<style scoped lang="scss">

.pill, div.filter-container > :deep(.pill) {
  padding: 4px 8px;
  background: #F8F9FB;
  border-radius: 12px;
  margin-right: 6px;
  font-size: 14px;
  user-select: none;
  border: 1px solid #efefef;
  flex-shrink: 0;

  &:hover {
    cursor: pointer;
  }


  &.active {
    border: 1px solid #505050;
  }
}
.resetter{
  background: var(--secondary);
}

</style>