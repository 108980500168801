<template>
  <div
    class="absolute top-0 pt-2 z-10 bg-transparent"
    :class="isMobile ? 'map-filter-width-mobile' : 'map-filter-width'"
  >
    <div
      class="flex gap-2 overflow-x-auto rounded-lg"
      :class="[{ 'hide-scrollbar': isMobile }, isMaxSmall ? 'justify-start' : 'justify-center']"
    >
      <StatusFilter
        v-if="featureFlags?.statusFilter"
        ref="firstItemRef"
        v-model:statusFilter="statusFilter"
        class="min-width-filter-buttons"
        :selected-statuses="statusFilter"
        :options="immobilieStatuses"
        ui-mode="map"
      />
      <PersonFilter
        v-if="featureFlags?.personFilter"
        v-model:personFilter="personFilter"
        class="min-width-filter-buttons"
        :selected-persons="personFilter"
        :options="organizationUsers"
        ui-mode="map"
      />
      <IonItem
        v-for="option in surveyDateFilterOptions"
        :key="option.value"
        class="mapFilterButton min-width-filter-buttons"
        lines="none"
      >
        <DateRangePicker
          class="rounded-lg whitespace-nowrap mx-0.5 flex items-center"
          :class="{ 'placeholder-style': !dateStartValues[option.value] }"
          :start="dateStartValues[option.value]"
          :end="dateEndValues[option.value]"
          :placeholder="option.label"
          :show-icon="true"
          :offset-y="8"
          :show-backdrop="false"
          @update:range="updateSurveyDateFilter(option.value, $event)"
          @update:single="updateSurveyDateFilter(option.value, $event)"
        />
      </IonItem>
      <ion-button
        v-if="statusFilter.length > 0 || personFilter.length > 0 || surveyDateFilter.length > 0"
        color="light"
        class="m-0 resetButton"
        @click="clearFilters"
      >
        <ion-icon
          slot="icon-only"
          :icon="refreshOutline"
        />
      </ion-button>
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>
import DateRangePicker from "@/components/Base/DateRangePicker.vue";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Immobilie from "@/models/immobilie.model";
import { ImmobilieStatus, translatedPropertyStatus } from "@/models/immobilie/interfaces/IImmobilie";
import User from "@/models/user";
import { DateFieldTypes, DateFilters } from "@/store/store.types";
import { IonButton, IonIcon, IonItem } from '@ionic/vue';
import { refreshOutline } from "ionicons/icons";
import { computed, ComputedRef, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
import PersonFilter from "./PersonFilter.vue";
import StatusFilter from './StatusFilter.vue';

const { t } = useI18n({ useScope: "global" });

const store = useStore();
const router = useRouter();
const organisationPrefix: ComputedRef<string> = computed(() => {
  const currentUser: User | null = User.query().first();
  return currentUser ? currentUser.organisationPrefix : "";
});
const { user } = useUser();
const featureFlags = computed(() => user.value?.organisation?.featureFlags);

const organizationUsers = computed(() => {
  return store.state.user.organizationUsers;
});

const isMobile = computed(() => {
  return store.getters["app/isMobile"];
});

const { isMaxSmall } = useScreenSize();

const immobilieStatuses = [
  {
    label: translatedPropertyStatus(ImmobilieStatus.ABGESCHLOSSEN, t),
    value: ImmobilieStatus.ABGESCHLOSSEN,
  },
  {
    label: translatedPropertyStatus(ImmobilieStatus.ANGELEGT, t),
    value: ImmobilieStatus.ANGELEGT,
  },
  {
    label: translatedPropertyStatus(ImmobilieStatus.ARCHIVIERT, t),
    value: ImmobilieStatus.ARCHIVIERT,
  },
  {
    label: translatedPropertyStatus(ImmobilieStatus.FREIGEGEBEN, t),
    value: ImmobilieStatus.FREIGEGEBEN,
  },
  {
    label: translatedPropertyStatus(ImmobilieStatus.GEPLANT, t),
    value: ImmobilieStatus.GEPLANT,
  },
  {
    label: translatedPropertyStatus(ImmobilieStatus.IN_DURCHFUEHRUNG, t),
    value: ImmobilieStatus.IN_DURCHFUEHRUNG,
  },
  {
    label: translatedPropertyStatus("", t),
    value: null,
  },
];

const surveyDateFilterOptions = ref<{ label: string, value: DateFieldTypes; }[]>([
  { label: t(`${organisationPrefix.value}hzba.datum.begehung`), value: "begehungsdatum" },
  { label: t(`${organisationPrefix.value}hzba.datum.sichtung`), value: "sichtungsdatum" }
]);

const personFilter = ref([]);
const statusFilter = ref([]);
const surveyDateFilter = ref<DateFilters>([]);
const dateStartValues = reactive<Record<string, string>>({});
const dateEndValues = reactive<Record<string, string>>({});

function clearFilters() {
  statusFilter.value = [];
  personFilter.value = [];
  surveyDateFilter.value = [];
  Object.keys(dateStartValues).forEach((key) => {
    dateStartValues[key] = "";
    dateEndValues[key] = "";
  });
}

watch(personFilter, (newVal) => {
  Immobilie.dispatch("setPersonFilter", newVal);
});

watch(statusFilter, (newVal) => {
  Immobilie.dispatch("setStatusFilter", newVal);
});

watch(surveyDateFilter, (newVal) => {
  Immobilie.dispatch("setSurveyDatumFilter", newVal);
});

const routeName = computed(() => { return router.currentRoute.value.name; });

watch(routeName, (newVal: any, oldVal: any) => {
  if (oldVal === "propertiesMap") {
    clearFilters();
  }
});

const updateSurveyDateFilter = (option: DateFieldTypes, value: any) => {
  if (Array.isArray(value)) {
    dateStartValues[option] = value[0];
    dateEndValues[option] = value[1];
  } else {
    dateStartValues[option] = value;
    dateEndValues[option] = "";
  }
  const existingFilters = surveyDateFilter.value.filter(el => el.option !== option);
  if (value !== "") {
    existingFilters.push({ option, value });
  }
  surveyDateFilter.value = existingFilters;
};

</script>

<style
  lang="scss"
  scoped
>
ion-checkbox {
  --border-color: var(--primary);
  --border-radius: 6px;
  --border-style: solid;
  --border-width: 2px;
}

ion-checkbox.checkbox-checked::part(container) {
  background: var(--primary);
  border-color: var(--primary);
}

ion-select {
  padding-top: 2px;
  padding-bottom: 2px;
}

ion-popover {
  --offset-y: 0.5rem;
  color: black;
  --width: fit-content;
  opacity: 0.9;
}

.highlighted {
  color: var(--highlight-color-focused, var(--primary, blue))
}

:deep(.placeholder-style) {
  opacity: var(--placeholder-opacity, 0.33);
}

.resetButton {
  --background: transparent;
  --border-radius: 16px;
  --color: var(--primary);
  --width: auto;
  --border-color: var(--primary);
  --border-style: solid;
  --border-width: 1px;
  font-size: 13px;
  height: 30px;
  --padding-end: 4px;
  --padding-start: 4px;
}

.map-filter-width {
  width: calc(100vw - var(--ion-safe-area-right) - var(--ion-safe-area-left) - var(--properties-sidebar-width));
}

.map-filter-width-mobile {
  --width-controls-left: calc(39px + 1rem); // control width + padding
  left: var(--width-controls-left);
  width: calc(100vw - var(--ion-safe-area-right) - var(--ion-safe-area-left) - var(--width-controls-left));
}

.min-width-filter-buttons {
  min-width: 130px;
}
</style>
