import apiClient from '@/api/api';
import { Fragenblock } from '@/models/ba/Fragenblock';
import { BestandsaufnahmeFlatJson, BestandsaufnahmeJson, HzbaStatusCode, HzbaStatusKey } from '@/models/ba/interfaces/IBestandsaufnahme';
import ErweitertesMedium from '@/models/erweitertes-medium.model';
import Unterschrift from '@/models/hzba/unterschrift.model';
import Immobilie from '@/models/immobilie.model';
import Localization from '@/models/localization.model';
import User from '@/models/user';
import { copyBaAndMergeWithImmobilie, sortArrayByProperty } from "@/utilities/sortArrayByProperty";
import { Model } from '@vuex-orm/core';
import { APhoto } from '../../photo/a-photo.model';
import StrapiMedia from '../../photo/strapi-media.model';
import Bestandsaufnahme from '../Bestandsaufnahme';
import { DateFilters } from '@/store/store.types';
import { isDateInRange } from '@/utilities/compare-helper';

export default class BestandsaufnahmeModel extends Model implements BestandsaufnahmeFlatJson {
  /**
   * Vuex ORM entity name
   */
  static entity = 'bestandsaufnahmes';

  /**
   * Backend variable definitions
   */
  public id!: number;
  public bilder!: APhoto[];
  public malus!: string;
  public malusColor!: string;
  public kommentar!: string;
  public begehungsdatum!: string;
  public sichtungsdatum!: string;
  public berichts!: ErweitertesMedium[];
  public mangellists!: string;
  public archive!: StrapiMedia;
  public matterportLink?: string;
  public immobilie!: number;
  public status!: HzbaStatusKey;
  public unterschrifts?: Unterschrift[];
  public auftraggeber!: string;
  public technProjektsteuerungName!: string;
  public technProjektsteuerungUnterschrift!: APhoto[];
  public reihenfolge!: string;
  public freigabeDatum!: string;
  public fazit!: string;
  public createdAt!: string;
  public updatedAt!: string;
  public createdBy!: string;
  public gutachter!: any;
  public updatedBy!: User;
  public bearbeitenderNutzer?: User;
  public bearbeitendesGeraetId?: string;
  public fragenblocks?: Fragenblock[];
  public name!: Localization;
  public nameMehrzahl!: Localization;
  public nameBericht!: Localization;
  public identifier!: string;
  public surveyType!: string;
  public isLocal!: boolean; // denotes BA is changed and not synchronized with Backend
  public hasUnsavedChanges!: boolean;
  public hasLocalImages!: boolean; // denotes BA is changed and not synchronized with Backend
  public isDownloaded!: boolean; // denotes user chose BA to be downloaded locally
  public areMinimumFragenblocksSet?: boolean;
  public removedMangelsFromMigrations?: any;

  /**
   * Default values
   */
  static fields() {
    return {
      id: this.attr(null),
      bilder: this.attr(null),
      malus: this.attr(null),
      malusColor: this.attr(null),
      name: this.attr(null),
      nameMehrzahl: this.attr(null),
      nameBericht: this.attr(null),
      identifier: this.attr(null),
      kommentar: this.attr(null),
      begehungsdatum: this.attr(null),
      sichtungsdatum: this.attr(null),
      archive: this.attr(null),
      matterportLink: this.attr(null),
      status: this.attr(null),
      unterschrifts: this.attr([]),
      auftraggeber: this.attr(null),
      technProjektsteuerungName: this.attr(null),
      technProjektsteuerungUnterschrift: this.attr(null),
      reihenfolge: this.attr(null),
      freigabeDatum: this.attr(null),
      isLocal: this.attr(false),
      hasUnsavedChanges: this.attr(false),
      hasLocalImages: this.attr(false),
      isDownloaded: this.attr(false),
      gutachter: this.attr(null),
      fazit: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      createdBy: this.attr(null),
      updatedBy: this.attr(null),
      bearbeitenderNutzer: this.attr(null),
      bearbeitendesGeraetId: this.attr(null),
      berichts: this.attr(null),
      mangellists: this.attr(null),
      immobilie: this.attr(null),
      // immobilie: this.hasMany(Immobilie, "bestandsaufnahme"),
      fragenblocks: this.attr([]),
      removedMangelsFromMigrations: this.attr(null),
      surveyType: this.attr(null),
      areMinimumFragenblocksSet: this.attr(false),
    };
  }

  public copyJson(): BestandsaufnahmeJson {
    return this.$toJson() as BestandsaufnahmeJson;
  }
}

export const bestandsaufnahme = {
  state: {
    loaded: false,
    page: 0,
    pageCount: undefined,
    searchResultsCount: null as number | null,
    totalSearchResultsCount: null as number | null,
    sortBy: { label: 'Begehungsdatum', fieldName: 'begehungsdatum', orderBy: 'asc'},
    searchTerm: "",
    stateFilter: [],
    dateFilter: [] as DateFilters,
    persistedBestandsaufnahmes: undefined,
    persistedBestandsaufnahmesLoaded: false,
  },
  // mutations defined for completeness
  // however: mutations can also be committed by BestandsaufnahmeModel.commit(state => state.example = 'value')
  mutations: {
    setLoaded (state: any) {
      state.loaded = true;
    },
    setPage (state: any, page: number) {
      state.page = page;
    },
    setPageCount (state: any, pageCount: number) {
      state.pageCount = pageCount;
    },
    setSearchResultsCount (state: any, count: number) {
      state.searchResultsCount = count;
    },
    setTotalSearchResultsCount (state: any, totalCount: number) {
      state.totalSearchResultsCount = totalCount;
    },
  },
  actions: {
    setSortBy: ({ state }: { state: any }, sortBy: string) => {
      state.sortBy = sortBy;
    },
    setSearchTerm: ({ state }: { state: any }, searchTerm: string) => {
      state.searchTerm = searchTerm;
    },
    setStateFilter: ({ state }: { state: any }, stateFilter: string) => {
      state.stateFilter = stateFilter;
    },
    setDateFilter: ({ state }: { state: any }, dateFilter: DateFilters) => {
      state.dateFilter = dateFilter;
    },

    loadPersistedBestandsaufnahmes: async ({ state }: { state: any}) => {
      const { bestandsaufnahmes } = await BestandsaufnahmeModel.dispatch("$fetchFromLocal");
      return bestandsaufnahmes || [];
    },
    storeMoverSurvey: async ({ state }: any, surveyJson: BestandsaufnahmeJson ) => {
        console.log("storeMoverSurvey", surveyJson);
        if ( surveyJson.isDownloaded ) {
            await BestandsaufnahmeModel.dispatch('$updateLocally', { data: surveyJson });

        } else {
            await BestandsaufnahmeModel.insertOrUpdate({ data: surveyJson });
        }
    },
    fetchMoverSurveyById: async ({ dispatch }: any, settings: { surveyId: string, saveToStore: boolean }) => {
        const res: any = await apiClient.get(`/bestandsaufnahmes/${settings.surveyId}`);
        if ( settings.saveToStore ) {
            res.data.data.isDownloaded = true;
            dispatch('storeMoverSurvey', res.data.data);
        }
        return res.data.data;
    },
    fetchMoverSurveys: async ({ state }: { state: any }) => {
        console.log("fetchMoverSurveys");
    }
  },
  getters: {
    loaded: (state: any) => state.loaded,
    page: (state: any) => state.page,
    pageCount: (state: any) => state.pageCount,
    sortBy: (state: any) => state.sortBy,
    searchTerm: (state: any) => state.searchTerm,
    stateFilter: (state: any) => state.stateFilter,
    dateFilter: (state: any) => state.dateFilter,
    persistedBestandsaufnahmes: (state: any) => BestandsaufnahmeModel.query().where('isDownloaded', true).get(),
    searchFilter: (state: any, getters: any) => (bestandsaufnahme: BestandsaufnahmeModel) => {
        const appliedStateFilters = getters.stateFilter?.filter((el: any) => !el.id.startsWith('LOCALE_'));

        const dateFilters = getters.dateFilter as DateFilters;
        const isInDateRange = dateFilters.every((dateFilter) => {
          if (dateFilter.option === "begehungsdatum") {
            return isDateInRange(bestandsaufnahme.begehungsdatum, dateFilter.value);
          }
          if (dateFilter.option === "sichtungsdatum") {
            return isDateInRange(bestandsaufnahme.sichtungsdatum, dateFilter.value);
          }
          return false;
        });

        const isStatusFilterActive = !appliedStateFilters || appliedStateFilters.length === 0 ||
            appliedStateFilters.find((el: any) => el.id === bestandsaufnahme.status);

        const property = Immobilie.find(bestandsaufnahme.immobilie);

        // this filter runs both in online and offline case
        // in online case the search was already done on the backend
        // only redo the search on client if there is the corresponding property in the store (property !== null)
        // => otherwise correct search results are filtered out
        // TODO: this is needed at the moment because the persisted surveys are always part of this list
        // the search filter could be applied separately in future and only the results joined
        if(property) {
            const isSearchTermFound = (!getters.searchTerm || Immobilie.getters('propMatchesFilter')(property, getters.searchTerm));
            return isSearchTermFound && isStatusFilterActive && isInDateRange;
        } else {
            return isStatusFilterActive && isInDateRange;
        }
    },
    sortAndMerge:(state: any, getters: any) => (array: BestandsaufnahmeModel[]) => {
        return array
            .filter(getters.searchFilter)
            .sort((a: BestandsaufnahmeModel, b: BestandsaufnahmeModel) =>
                sortArrayByProperty(
                    copyBaAndMergeWithImmobilie(a),
                    copyBaAndMergeWithImmobilie(b),
                    getters.sortBy
                )
            );
    },
    moverSurveys: (state: any, getters: any, rootState: any, rootGetters: any) => {
        const isLocaleReset = getters.stateFilter.some((item: any) => item.id === HzbaStatusCode.RESET)
        const showOfflineOnly = getters.stateFilter?.find((el: any) => el.id === HzbaStatusCode.OFFLINE)
        const handleOfflineCase = rootGetters['app/isOffline'] || showOfflineOnly;

        if (!handleOfflineCase) {
            let surveys = BestandsaufnahmeModel.all();

            if (getters.stateFilter.length > 0 && !isLocaleReset) {
                // Apply state filter if not reset
                surveys = surveys.filter((el: BestandsaufnahmeModel) =>
                    getters.stateFilter.some((item: any ) => item.id === el.status)
                );
            }

            // Apply sorting and merging
            return getters.sortAndMerge(surveys, getters.sortBy);
        } else {
            // Handle offline case
            return getters.sortAndMerge(getters.persistedBestandsaufnahmes, getters.searchFilter, getters.sortBy);
        }
    },
    moverSurveysJson: (state: any, getters: any) => {
        return getters.moverSurveys.map((el: BestandsaufnahmeModel) => new Bestandsaufnahme(el.copyJson()));
    }
  },
};
