export const getDateQuery = (date: string | string[]) => {
    if (Array.isArray(date)) {
        return {
            start: { $gte: `${date[0]}T00:00:00Z` },
            end: { $lte: `${date[1]}T23:59:59Z` }
        };
    } else {
        return {
            start: { $gte: `${date}T00:00:00Z` },
            end: { $lte: `${date}T23:59:59Z` }
        };
    }
};