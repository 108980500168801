import PredefinedGeometriesModal from "@/components/v2/Map/PredefinedGeometriesModal.vue";
import { getRouterElement } from "@/utilities/router-helper";
import { generateUUID } from "@ionic/cli/lib/utils/uuid";
import { modalController } from "@ionic/vue";
import MapboxDraw, { DrawMode as DefaultDrawMode, MapboxDrawControls, MapboxDrawOptions } from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import cloneDeep from "lodash.clonedeep";
import { Feature, LngLat } from "maplibre-gl";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ExtendDrawBar } from "../../components/v2/Map/extendDrawBar";

type DrawMode = DefaultDrawMode | "draw_paint_mode" | "RotateMode";

type DrawCreateCallback = (feature: any) => void;
type DrawEditCallback = (drawItem: any) => void;

type DrawOptionControls = keyof MapboxDrawControls | "paint" | "rotate";

export type PredefinedGeometry = {
    name: string;
    type: "rectangle" | "circle";
    width?: number;
    height?: number;
    style?: string;
    imageUrl?: string;
};

export default function useOnceDraw(mapIdentifier: string) {

    let draw: MapboxDraw;
    let drawOptions: MapboxDrawOptions | undefined;
    const drawControls: any = [];

    // variables are non-reactive to not run into timing problems with vue updating cycles
    // they are needed because the freehand-draw library fires events in some cases differently than the standard draw modes
    let keepInEditMode = false;
    let newFreehandDrawing: null | string[] = null;

    const isPredefinedGeomsInProgress = ref(false);

    const controlsMap: Partial<Record<DrawOptionControls, string>> = {
        line_string: "mapbox-gl-draw_line",
        point: "mapbox-gl-draw_point",
        polygon: "mapbox-gl-draw_polygon",
        paint: "draw_paint_class",
        rotate: "rotate-button.right-button"
    };
    const paintIcon = "paint-brush-icon";

    const drawnEdgesRequired = ref(0);
    const drawnEdgesExisting = ref(0);
    const lastMode = ref<DrawMode>();
    const drawCreateCallback = ref<DrawCreateCallback | null>(null);
    const drawEditCallback = ref<DrawEditCallback | null>(null);
    const beingEdited = ref<null | any[]>(null); // stores the geometry of the selected feature to be able to reset it if the user cancels the edit
    const beingCreated = ref<null | Feature["id"]>(null); // stores the id of the feature that is currently being created, there is an exception for freehand drawing
    const deleteSafeguard = ref(0); // to prevent deleting features that are already confirmed

    const { t } = useI18n({ useScope: 'global' });

    const setDrawInstance = (_draw: MapboxDraw, _drawOptions?: MapboxDrawOptions) => {
        draw = _draw;
        drawOptions = _drawOptions;
    };

    const setDeleteSafeGuard = (hasEmptyEntry = true) => {
        // -1 to get last element, another -1 if features list has empty entry like for polygon, line_string or point
        deleteSafeguard.value = draw.getAll().features.length - (hasEmptyEntry ? 2 : 1);
    };

    const useConfirmAndCancel = computed(() => {
        return mapIdentifier === "surveyMap" && (drawnEdgesRequired.value > 0 || beingEdited.value !== null || isPredefinedGeomsInProgress.value);
    });

    const confirmDrawButtonDisabled = computed(() => {
        return drawnEdgesExisting.value < drawnEdgesRequired.value;
    });

    const getFreedrawButton = () => {
        return document.querySelector(`#${mapIdentifier} .${controlsMap.paint}`);
    };

    const getRotateButton = () => {
        return document.querySelector(`#${mapIdentifier} .${controlsMap.rotate}`);
    };

    // get last feature in features list that is not null or an empty array
    function getLastFeature(endPoint = -1) {
        const features = draw?.getAll() ?? [];
        let feature;
        for (let i = features.features.length - 1; i > endPoint; i--) {
            const feat = features.features[i];
            // @ts-ignore coordinates not part of type Geometry, but do exist in runtime
            if ((feat.geometry.type === "Polygon" && feat.geometry?.coordinates?.[0]?.length >= 2) ||
                (feat.geometry.type === "LineString" && feat.geometry?.coordinates?.length >= 2) ||
                (feat.geometry.type === "Point" && feat.geometry?.coordinates?.length === 2) ||
                (feat.geometry.type === "MultiLineString" && feat.geometry?.coordinates?.[0]?.length >= 2) ||
                isPredefinedGeomsInProgress.value
            ) {
                const mode = draw?.getMode() as DrawMode;
                // user is clicking confirm button while drawing a line or polygon, remove last point in coordinates in this case
                // otherwise the server will create an entry with the last point, but in the drawing the last point will be canceled
                if (mode === "draw_polygon" && feat.geometry.type === "Polygon") {
                    feat.geometry = Object.assign({}, feat.geometry, { coordinates: [feat.geometry?.coordinates?.[0].slice(0, -1) ?? []] });
                } else if (mode === "draw_line_string" && feat.geometry.type === "LineString") {
                    feat.geometry = Object.assign({}, feat.geometry, { coordinates: feat.geometry?.coordinates?.slice(0, -1) ?? [] });
                }
                feature = feat;
                break;
            }
        }
        return feature;
    }

    function checkDeleteLastFeatureOnCanvas() {
        if ((drawnEdgesRequired.value > 0 && drawnEdgesExisting.value > 0) || isPredefinedGeomsInProgress.value) {
            if (beingCreated.value) {
                draw?.delete(beingCreated.value.toString());
                beingCreated.value = null;
            } else {
                const feature = getLastFeature(deleteSafeguard.value);
                if (feature?.id) {
                    draw?.delete(feature.id.toString());
                }
            }
        }
    }

    const resetDrawing = () => {
        drawnEdgesExisting.value = 0;
        drawnEdgesRequired.value = 0;
        newFreehandDrawing = null;
        beingCreated.value = null;
    };

    const resetEditing = () => {
        beingEdited.value = null;
    };

    const cancelDrawing = () => {
        if (beingEdited.value && !isPredefinedGeomsInProgress.value) {
            // @ts-ignore
            beingEdited.value.forEach((edit) => {
                const feature = draw.get(edit.id);
                if (feature) {
                    feature.geometry = edit.geometry;
                    draw.add(feature);
                }
            });
            resetEditing();
        } else {
            checkDeleteLastFeatureOnCanvas();
            resetDrawing();
        }
        getFreedrawButton()?.classList?.remove('active');
        if (isPredefinedGeomsInProgress.value) {
            isPredefinedGeomsInProgress.value = false;
            resetEditing();
        }
    };

    const onCancelDrawing = () => {
        cancelDrawing();
        draw?.changeMode("simple_select");
    };

    // set the callback function that is called when a drawing is confirmed
    // workaround to not introduce too many global state variables/functions into this composable
    const setDrawCreateCallback = (callback: DrawCreateCallback) => {
        drawCreateCallback.value = callback;
    };

    const setDrawEditCallback = (callback: DrawEditCallback) => {
        drawEditCallback.value = callback;
    };

    const onConfirmDrawing = () => {
        if (!confirmDrawButtonDisabled.value) {
            if (beingEdited.value && !isPredefinedGeomsInProgress.value) {
                drawEditCallback.value?.(beingEdited.value[0].id);
                resetEditing();
            } else {
                drawCreateCallback.value?.(getLastFeature());
                resetDrawing();
                setDeleteSafeGuard(false);
            }
            draw?.changeMode("simple_select");
            if (isPredefinedGeomsInProgress.value) {
                isPredefinedGeomsInProgress.value = false;
                resetEditing();
            }
        }
    };

    // to handle clicks outside of the currently selected features, either to confirm or to start editing
    const checkConfirmOrEdit = () => {
        if (drawnEdgesRequired.value > 0 && newFreehandDrawing !== null) {
            if (keepInEditMode) {
                // necessary because click event is fired after onDrawModeChange for freehand drawing (for the others it is the other way around)
                // in first run do not call onConfirmDrawing, only in second run
                keepInEditMode = false;
            } else if (JSON.stringify(newFreehandDrawing) !== JSON.stringify(draw?.getSelectedIds())) {
                newFreehandDrawing = null;
                onConfirmDrawing();
            }
        } else if (beingEdited.value && JSON.stringify(beingEdited.value.map((feature) => feature.id)) !== JSON.stringify(draw?.getSelectedIds())) {
            onConfirmDrawing();
        }
    };

    // check mode if a click on the feature means to switch to edit mode
    const checkIfEditModePossible = () => {
        const mode = draw?.getMode() as DrawMode;
        if (drawnEdgesRequired.value === 0 && mode !== "RotateMode" && mode !== "static") {
            // clone the geometry of the selected feature to be able to reset it if the user cancels the edit
            beingEdited.value = draw?.getSelectedIds()
                // @ts-ignore
                .map((id: string) => ({ id, geometry: cloneDeep(draw.get(id)?.geometry) }))
                .filter((feature) => feature.geometry !== undefined);

            if (beingEdited.value.length === 0) {
                resetEditing();
            }
        }
    };

    // if draw-related return true, else return false
    function onClickDraw() {
        const mode: DrawMode = draw?.getMode() as DrawMode;
        checkConfirmOrEdit();
        checkIfEditModePossible();
        if ((mode === "draw_line_string" ||
            mode === "draw_point" ||
            mode === "draw_polygon" ||
            mode === "draw_paint_mode")) {
            drawnEdgesExisting.value += 1;
            if (mode !== "draw_paint_mode") {
                const features = draw?.getAll() ?? [];
                beingCreated.value = features.features.slice(-1)[0].id;
            }
            return true;
        }
        if (mode === "direct_select") {
            return true;
        }
        return false;
    }

    const afterModeChange = (mode: DrawMode) => {
        // if the mode is changed to another draw mode, delete the last feature on the canvas manually
        // if the same mode button is clicked again, the library automatically deletes the last feature
        if ((mode === "draw_line_string" ||
            mode === "draw_point" ||
            mode === "draw_polygon" ||
            mode === "draw_paint_mode" ||
            mode === "RotateMode") &&
            lastMode.value !== mode) {
            checkDeleteLastFeatureOnCanvas();
            // reset after deleting the last feature
            resetDrawing();
        } else {
            checkConfirmOrEdit();
        }

        if (drawnEdgesRequired.value > 0 && mode === "simple_select" && newFreehandDrawing === null) {
            newFreehandDrawing = draw?.getSelectedIds();
            keepInEditMode = true;
        }

        if (lastMode.value === "draw_paint_mode" && mode !== "draw_paint_mode") {
            getFreedrawButton()?.classList?.remove('active');
        }

        if (mode !== "RotateMode") {
            getRotateButton()?.classList?.remove('active');
        }

        lastMode.value = mode;
        switch (mode) {
            case "draw_line_string":
                drawnEdgesRequired.value = 2;
                setDeleteSafeGuard();
                break;
            case "draw_point":
                drawnEdgesRequired.value = 1;
                setDeleteSafeGuard();
                break;
            case "draw_polygon":
                drawnEdgesRequired.value = 3;
                setDeleteSafeGuard();
                break;
            case "draw_paint_mode":
                getFreedrawButton()?.classList?.add('active');
                setDeleteSafeGuard(false);
                drawnEdgesRequired.value = 1;
                break;
            default:
                break;
        }
    };

    const changeModeTo = (mode: DrawMode) => {
        draw?.changeMode(mode as any);
        afterModeChange(mode);
    };

    const onDrawModeChange = () => {
        const mode = draw?.getMode() as DrawMode;
        afterModeChange(mode);
    };

    const onDrawCreate = () => {
        const mode = draw?.getMode() as DrawMode;
        const feature = getLastFeature();
        if (mode === "draw_point") {
            drawnEdgesExisting.value = 1;
        } else if (mode === "simple_select" && feature?.geometry.type === "MultiLineString") {
            // @ts-ignore
            newFreehandDrawing = [feature.id?.toString()];
            keepInEditMode = true;
            // @ts-ignore
            draw.changeMode("direct_select", { featureId: newFreehandDrawing[0] });
        }
    };

    const calculateOffsetAfterDraw = (drawOptions: any) => {
        // if AppMap is refactored to use addControl this could make this function obsolete
        // e.g. mapLibre.value.addControl(new CustomControl(topRightControl.value), Positions.TOP_RIGHT);
        if (drawOptions.controls) {
            const margin = 20;
            const controlHeight = 29;
            //rotate is in drawOptions.controls but not shown with the other buttons atm
            const rotateButtonException = drawOptions.controls?.rotate ? controlHeight : 0;
            // check which controls are enabled
            const offset = Object.values(drawOptions.controls as Record<string, boolean>)
                .reduce((acc: number, val: boolean) => acc + (val ? 1 : 0), 0);
            return (offset * controlHeight + margin) - rotateButtonException;
        }
        return null;
    };

    function collectControlElements() {
        if (!drawOptions?.controls) return;
        const mapElement = document.getElementById(mapIdentifier);

        Object.keys(controlsMap).forEach((key) => {
            // @ts-ignore paint and rotate not part of MapboxDrawControls
            if (drawOptions?.controls?.[key] === true) {
                const cssIdentifier = controlsMap[key as DrawOptionControls];
                if (cssIdentifier) {
                    drawControls.push(mapElement?.getElementsByClassName(cssIdentifier)[0]);
                }
            }
        });
    }

    function setControlVisibility(displayValue: string) {
        drawControls.forEach((control: { style: { display: string; }; }) => {
            control.style.display = displayValue;
        });
    }

    // additional control button "Freihandskizze"
    const drawPaintBtn = {
        on: "click",
        controlName: "paint",
        action: () => {
            if (draw?.getMode() === "draw_paint_mode") {
                draw?.changeMode("simple_select");
                onDrawModeChange();
            } else {
                draw.changeMode("draw_paint_mode");
                onDrawModeChange();
            }
        },
        classes: [paintIcon, controlsMap.paint],
        title: t('appmap.freehand'),
    };
    const getExtendedDrawControl = () => new ExtendDrawBar({
        draw: draw,
        drawOptions: drawOptions,
        buttons: [
            drawPaintBtn,
        ],
    });

    function addRectangleToDraw(centerPoint: any, width: number, height: number) {
        const cx = centerPoint[0];
        const cy = centerPoint[1];

        const meterToDegreeFactorLat = 1 / 111320; // Roughly 1 degree latitude = 111.32 km
        const meterToDegreeFactorLon = meterToDegreeFactorLat / Math.cos(centerPoint[1] * (Math.PI / 180)); // Adjust for longitude based on latitude
        const halfWidthDegrees = (width / 2) * meterToDegreeFactorLon; // Half width in degrees (longitude)
        const halfHeightDegrees = (height / 2) * meterToDegreeFactorLat; // Half height in degrees (latitude)
        const coordinates = [
            [cx - halfWidthDegrees, cy - halfHeightDegrees], // Bottom-left
            [cx + halfWidthDegrees, cy - halfHeightDegrees], // Bottom-right
            [cx + halfWidthDegrees, cy + halfHeightDegrees], // Top-right
            [cx - halfWidthDegrees, cy + halfHeightDegrees], // Top-left
            [cx - halfWidthDegrees, cy - halfHeightDegrees]  // Close the rectangle
        ];
        const rectangleFeature: any = {
            type: "Feature",
            id: generateUUID(),
            geometry: {
                type: "Polygon",
                coordinates: [coordinates],
            },
            properties: {},
        };

        draw.add(rectangleFeature);
    }

    function addCircleToDraw(centerPoint: any, radius: number) {
        const radiusInKm = turf.convertLength(radius, "meters", "kilometers");
        const circleTurfFeature = turf.circle(centerPoint, radiusInKm, {
            steps: 64, // Number of points to approximate the circle
            units: "kilometers",
        });
        const circleFeature: any = {
            type: "Feature",
            id: generateUUID(),
            geometry: circleTurfFeature.geometry,
            properties: {}
        };

        draw.add(circleFeature);
    }

    const openGeometryModal = async (geometries: Record<string, PredefinedGeometry[]>, center?: LngLat) => {
        const modal = await modalController.create({
            component: PredefinedGeometriesModal,
            componentProps: { geometries },
            cssClass: "v2Modal",
            canDismiss: true,
            presentingElement: getRouterElement()
        });
        modal.present();
        modal.onDidDismiss().then((params: any) => {
            if (params?.data) {
                isPredefinedGeomsInProgress.value = true;
                const data = params.data;
                const centerArray = center ? [center.lng, center.lat] : [0, 0];
                switch (data.type) {
                    case "rectangle": addRectangleToDraw(centerArray, data.width, data.height); break;
                    case "circle": addCircleToDraw(centerArray, data.radius); break;
                    default: break;
                }
                // string type cast necessary due to multiple typescript definitions of changeMode()
                draw?.changeMode("simple_select" as string, { featureIds: [getLastFeature()?.id] });
                onClickDraw();
            }
        });
    };

    const toggleRotateMode = () => {
        const mode = draw?.getMode() as DrawMode;
        if (mode !== "RotateMode") {
            changeModeTo("RotateMode");
            getRotateButton()?.classList?.add('active');
            cancelDrawing();
        } else {
            getRotateButton()?.classList?.remove('active');
            draw?.changeMode("simple_select");
        }
    };

    return {
        setDrawInstance,
        onClickDraw,
        onDrawModeChange,
        onDrawCreate,
        useConfirmAndCancel,
        confirmDrawButtonDisabled,
        onCancelDrawing,
        onConfirmDrawing,
        setDrawCreateCallback,
        setDrawEditCallback,
        calculateOffsetAfterDraw,
        getExtendedDrawControl,
        collectControlElements,
        setControlVisibility,
        openGeometryModal,
        addCircleToDraw,
        addRectangleToDraw,
        toggleRotateMode
    };
}
