import apiClient from "@/api/api";
import { putBestandsaufnahme } from "@/api/ModelApi";
import { useProperties } from "@/composables/Property/useProperties";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import { HzbaStatusCode, HzbaStatusKey, translatedHzbaStatus } from "@/models/ba/interfaces/IBestandsaufnahme";
import BestandsaufnahmeModel from '@/models/ba/models/bestandsaufnahme.model';
import Immobilie from '@/models/immobilie.model';
import { DateFilters } from "@/store/store.types";
import { Monitoring } from "@/utilities/monitoring";
import { getDateQuery } from "@/utilities/query-helper";
import { Network } from "@capacitor/network";
import { alertController, AlertInput } from "@ionic/vue";
import axios from "axios";
import { default as qs, default as QueryString } from 'qs';
import { computed, ref } from "vue";

export interface FilterOption {
    name: string,
    id: string,
    resetOtherFilters?: boolean,
    resetSelfWhenOthersActive?: boolean,
    onlyShowWhenOthersActive?: boolean;
    dontShowWhenNotSelected?: boolean;
}

export function useBestandsaufnahmens() {

    const store = useStore();
    const { user } = useUser();

    const featureFlags = computed(() => user.value!.organisation?.featureFlags)

    const isFetchingBestandsaufnahmes = ref(false);
    const currentProject = computed(() => store.state.user.currentUserProject)

    /**
     * Build the query for the backend.
     */
    const getNextQuery = async () => {
        const pageSize = 25;

        BestandsaufnahmeModel.commit((state) => state.page = (BestandsaufnahmeModel.getters("page") + 1));
        const sortBy = BestandsaufnahmeModel.getters("sortBy");

        const searchString = BestandsaufnahmeModel.getters("searchTerm");
        const stateFilters = BestandsaufnahmeModel.getters("stateFilter");
        const dateFilter = BestandsaufnahmeModel.getters("dateFilter") as DateFilters;

        const query: any = {
            pagination: {
                page: BestandsaufnahmeModel.getters("page"),
                pageSize: pageSize
            }
        };


        if (sortBy) {
            let sortObj: any;
            if ( sortBy.fieldName=== "malusColor" ) {
                sortObj = [ [sortBy.fieldName] + ":" + sortBy.orderBy, "malus:" + sortBy.orderBy ];
            } else {
                sortObj = { [sortBy.fieldName]: sortBy.orderBy }
            }
            if (sortBy.subObject) {
                query.sort = { [sortBy.subObject]: sortObj }
            } else {
                query.sort = sortObj;
            }
        }

        if ((searchString ?? "") != "") {
            query.filters = {
                $and: [
                    {
                        $or: [
                            { immobilie: { name: { $containsi: searchString, } } },
                            { immobilie: { eigentuemer: { $containsi: searchString } } },
                            { immobilie: { strasse: { $containsi: searchString } } },
                            { immobilie: { plz: { $containsi: searchString } } },
                            { immobilie: { stadt: { $containsi: searchString } } },
                            { immobilie: { externeObjektNr: { $containsi: searchString } } },
                            { immobilie: { baujahr: { $containsi: searchString } } },
                        ]
                    }
                ]
            };
        }

        if (!query.filters) { query.filters = { $and: [] } }

        if (dateFilter) {
            dateFilter.forEach((filter) => {
                const { start, end } = getDateQuery(filter.value);
                query.filters.$and.push({ [filter.option]: start });
                query.filters.$and.push({ [filter.option]: end });
            });

        }

        const backendFilter = stateFilters.filter((el: FilterOption) => !el.id.startsWith('LOCALE'));
        if (backendFilter && stateFilters.length > 0) {
            const stateQuery: any = { $or: [] }
            backendFilter.forEach((el: FilterOption) => {
                stateQuery.$or.push({ status: el.id })
            })
            query.filters.$and.push(stateQuery)
        } else {
            query.filters.$and.push({ status: { $ne: 'ARCHIVIERT' } })
        }
        return query;
    }

    /**
     * Stringifies the query
     */
    const getNextQueryString = async () => {
        return QueryString.stringify(await getNextQuery())
    }

    /**
     * Load Bas from IndexedDB but do not store them in vuex directly. We don't know if we want to show those properties at this state due to search/filter function;
     */
    const loadPersistedBestandsaufnahmes = async (): Promise<BestandsaufnahmeModel[]> => {
       return await BestandsaufnahmeModel.dispatch("loadPersistedBestandsaufnahmes");
        // return BestandsaufnahmeModel.getters("persistedBestandsaufnahmes")
    }

    /**
     * Fetch ba's from backend.
     * If we find downloaded but not local edited ba's, we load them fully instead of the preview
     */
    const CancelToken = axios.CancelToken;
    const cancelableSources: any[] = [];

    const loadBestandsaufnahmens = async (query?: string) => {
        console.log("load Bestandsaufnahmes")
        if (isFetchingBestandsaufnahmes.value) {
            console.log("SOURCE CANCEL");

            cancelableSources.forEach(el => {
                el.cancel('Operation canceled by the user.')
            })
        }

        // NOTE: in offline-case search result counts are not set here in the composable
        BestandsaufnahmeModel.commit((state) => {
            state.searchResultsCount = null;
            state.totalSearchResultsCount = null;
        });

        const persistedBas = await loadPersistedBestandsaufnahmes();
        const stateFilter = BestandsaufnahmeModel.getters("stateFilter")
        try {
            const status = await Network.getStatus();
            if(!status.connected || stateFilter.find((filter: any) => filter.id === HzbaStatusCode.OFFLINE)) {
                if(!status.connected) {
                    console.log(`Loading Bestandsaufnahmes failed: Network Error`);
                }

                await BestandsaufnahmeModel.insert({ data: persistedBas });
                BestandsaufnahmeModel.commit((state) => {
                    state.page = 1;
                    state.pageCount = 1;
                    state.loaded = true;
                });
            } else {

                isFetchingBestandsaufnahmes.value = true;
                const source = CancelToken.source();
                cancelableSources.push(source)

                const res = await apiClient.get(`/bestandsaufnahmes?${query ? query : await getNextQueryString()}`, {
                    cancelToken: source.token
                });

                const fetchedBackendBaRes = (res.data as any);
                const pagination = fetchedBackendBaRes.pagination;

                const retrievedCount = pagination.page >= pagination.pageCount ? pagination.total : pagination.page * pagination.pageSize;
                BestandsaufnahmeModel.commit((state) => {
                    state.searchResultsCount = retrievedCount
                    state.totalSearchResultsCount = pagination.total
                });

                // Ba's to push.
                const basToPush: BestandsaufnahmeModel[] = [];

                for (const ba of fetchedBackendBaRes.results) {
                    await Immobilie.dispatch('addToFallbackProperties', ba.immobilie);

                    ba.immobilie = ba.immobilie?.id;

                    // Bas with local edits won't be updated.
                    const persistedEditedBa = persistedBas?.find((local: any) => local.id === ba.id && local.isLocal)
                    if (persistedEditedBa) {
                        basToPush.push(persistedEditedBa)
                        continue;
                    }

                    // Downloaded bas will be updated, but fully instead of preview only.
                    const localDownloadedUneditedBa = persistedBas?.find((local: any) => local.id === ba.id);
                    if (localDownloadedUneditedBa) {
                        if (new Date(localDownloadedUneditedBa!.updatedAt) < new Date(ba.updatedAt)) {
                            // new update found from backend. load full ba for this.
                            // console.log("DOWNLOAD BA", ba.id)
                            const newDownloadedBa = await BestandsaufnahmeModel.dispatch("fetchMoverSurveyById", {surveyId: ba.id, saveToStore: true})

                            newDownloadedBa && basToPush.push(newDownloadedBa);
                        } else {
                            basToPush.push(localDownloadedUneditedBa);
                        }
                        continue;
                    }

                    // Ba is neither local edited, nor downloaded and unedited nor should be downloaded
                    basToPush.push(ba);
                }
                await BestandsaufnahmeModel.insert({ data: basToPush });

                // Post status updates
                store.commit('app/updateBestandsaufnahmesLastRefresh');
                BestandsaufnahmeModel.commit((state) => {
                    state.page = pagination.page;
                    state.pageCount = pagination.pageCount;
                    state.loaded = true;
                });

                isFetchingBestandsaufnahmes.value = false;
            }
        } catch (error: any) {
            Monitoring.chainError("Loading Bestandsaufnahmes failed", error);
        }
    }

    const setBestandsaufnahmeStatus = (ba: Bestandsaufnahme) => async (status: HzbaStatusKey) => {
        try {
            const isOnline = store.getters["app/isOnline"];
            let updatedBaJson = null;

            if (isOnline) {
                const oldSurvey = await ba.toClassJson();
                updatedBaJson = await putBestandsaufnahme(ba.id, currentProject.value.id, { status }, oldSurvey);
            } else {
                updatedBaJson = await ba.toClassJson();
                updatedBaJson.status = status;
                updatedBaJson.hasUnsavedChanges = true;
            }
            await BestandsaufnahmeModel.dispatch('storeMoverSurvey', updatedBaJson);

            if(updatedBaJson && ba.id === store.state.currentHzba?.currentBa?.id) {
                updatedBaJson.isLocal = false; // legacy property, make sure it is not set to true
                const updatedBa = new Bestandsaufnahme(updatedBaJson);
                store.commit('currentHzba/setCurrentBa', updatedBa);
            }

        } catch (error: any) {
            Monitoring.chainError("Error while setting status of Bestandsaufnahme", error);
        }
    }

    const openChangeStatusAlert = async (ba: Bestandsaufnahme, t: any) => {
        if(!featureFlags?.value.survey?.changeStatus) {
            return;
        }
        const alertButtons = [
          {
            text: t('hzba.buttons.confirm'),
            handler: setBestandsaufnahmeStatus(ba)
          },
          t('hzba.buttons.cancel')
        ];
        const statuses: AlertInput[] = [
            HzbaStatusCode.ANGELEGT,
            HzbaStatusCode.GEPLANT,
            HzbaStatusCode.IN_DURCHFUEHRUNG,
            HzbaStatusCode.ABGESCHLOSSEN,
            HzbaStatusCode.FREIGEGEBEN,
            HzbaStatusCode.ARCHIVIERT
        ].map((code) => ({
            label: translatedHzbaStatus(code, t),
            type: "radio",
            value: code,
            checked: ba.status === code
        }));

        const alert = await alertController.create({
          header: t("hzba.updateStatus"),
          buttons: alertButtons,
          inputs: statuses
        });
        alert.present();
    }

    /**
     * Remove downloaded Ba from indexedDb
     * @param ba
     */
    const removeDownloadedBa = async (ba?: Bestandsaufnahme) => {
        const immobilie = ba?.immobilie && Immobilie.find(ba?.immobilie) as (Immobilie | undefined);
        if (!ba) {
            Monitoring.error('removeDownloadedBa: ba is undefined')
            return;
        }

        const baModel = BestandsaufnahmeModel.find(ba.id);
        if (baModel && immobilie) {
            // baModel.isDownloaded = false;
            ba.isDownloaded = false;

            await BestandsaufnahmeModel.dispatch('$deleteFromLocal', baModel.id);

            // with current API it's not possible to  delete only from local storage but not from store, thus we need to insert afterwards again
            // console.log("remove downloads add to vuex", await ba.toClassJson());

            // await BestandsaufnahmeModel.dispatch('removeFromPersistedBestandsaufnahmes', baModel);
            await BestandsaufnahmeModel.insertOrUpdate({ data: await ba.toClassJson() });

            const { considerRemoveImmobilieFromDownloaded } = useProperties();
            await considerRemoveImmobilieFromDownloaded(baModel.immobilie)
        }
    }

    const doLocalBestandsaufnahmesExists = async () => {
        return BestandsaufnahmeModel.all().find(el => el.isLocal);
    }

    const isUpgradeAvailable = async (ba: Bestandsaufnahme) => {
        const res = await apiClient.get(`/bestandsaufnahmes/isUpgradeAvailable/${ba.id}`);
        return res.data;
    }

    const migrateBa = async (ba: Bestandsaufnahme, preview: Boolean) => {
        const res = await apiClient.put(`/bestandsaufnahmes/upgradeTemplate/${ba.id}`, { preview: preview });
        return res.data
    }


    const getBackupsForBa = async (baId: number) => {
        const query = qs.stringify({
            sort: ['createdAt:desc'],
            filters: {
                bestandsaufnahme: {
                    id: {
                        $eq: baId,
                    },
                },
            },
            fields: ['createdAt'],
            pagination: {
                pageSize: 10,
                page: 1,
            },
            publicationState: 'live',
            locale: ['en'],
        }, { encodeValuesOnly: true });

        const res = await apiClient.get(`/backup-bestandsaufnahmes?${query}`)
        return res.data.data;
    }

    const runBackup = async (backupId: number) => {
        const res = await apiClient.put(`/bestandsaufnahmes/runBackup/${backupId}`)
        return res;
    }

    return {
        loadBestandsaufnahmens,
        removeDownloadedBa,
        runBackup,
        getBackupsForBa,
        isUpgradeAvailable,
        doLocalBestandsaufnahmesExists,
        migrateBa,
        openChangeStatusAlert
    };
}
