<template>
  <IonPage>
    <IonHeader v-if="isMobile">
      <Toolbar
        :title="$t('appmap.selectGeometry')"
      >
        <template #leading>
          <IonButtons slot="start">
            <IonButton
              slot="start"
              @click="modalController.dismiss()"
            >
              <IonIcon :icon="arrowBack" /> {{ $t("buttons.zurueck") }}
            </IonButton>
          </IonButtons>
        </template>
      </Toolbar>
    </IonHeader>
    <div 
      v-else
      class="header headline3 m-5 border-none"
    >
      {{ $t('appmap.selectGeometry') }}
    </div>

    <IonContent>
      <IonGrid 
        class="mt-4 ml-5"
      >
        <IonRow>
          <IonCol
            v-for="geometry in flatGeometries"
            :key="geometry.name"
            :size="isMaxXSmall ? '12' : '4'"
            class="cursor-pointer"
            @click="selectedGeometry = geometry"
          >
            <div 
              class="flex flex-col gap-4 h-full items-center p-2 rounded-lg"
              :class="{ 'bg-gray-200': selectedGeometry?.name === geometry.name }"
            >
              <AImage
                :src="`${strapiUrl}/${geometry.imageUrl}`"
                :image-class="isMaxXSmall ? 'h-full' : 'w-32'"
              />
              <span class="mt-auto">{{ geometry.name }}</span>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <div 
        class="my-8 flex gap-4"
        :class="isMaxXSmall ? 'flex-col mx-8' : 'flex-row mr-4 justify-end'"
      >
        <AButton
          :disabled="selectedGeometry ? false : true"
          :btn-primary="true"
          class="px-16 py-6 text-lg"
          @click="modalController.dismiss(selectedGeometry)"
        >
          {{ $t(`buttons.insert`) }}
        </AButton>
        <AButton
          :btn-secondary="true"
          class="px-16 py-6 text-lg"
          @click="modalController.dismiss()"
        >
          {{ $t('hzba.buttons.abbrechen') }}
        </AButton>
      </div>
    </IonContent>
  </IonPage>
</template>

<script
  lang="ts"
  setup
>
import { strapiUrl } from "@/api/api";
import AButton from "@/components/Base/AButton.vue";
import AImage from "@/components/Base/AImage.vue";
import Toolbar from "@/components/Navigation/Toolbar";
import { PredefinedGeometry } from "@/composables/Map/useDraw";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow, modalController } from '@ionic/vue';
import { arrowBack } from 'ionicons/icons';
import { computed, PropType, ref } from 'vue';

const selectedGeometry = ref<PredefinedGeometry | null>(null);

const props = defineProps({
  geometries: {
    type: Object as PropType<Record<string, PredefinedGeometry[]>>,
    required: true
  }
});

const store = useStore();
const { isMaxXSmall } = useScreenSize();

const isMobile = computed(() => {
  return store.getters["app/isMobile"];
});

//helper until tabs are implemented
const flatGeometries = computed(() => {
  return Object.values(props.geometries).flat();
});

</script>



<style
  scoped
  lang="scss"
>
ion-icon {
  color: var(--white100);
}

ion-button {
  color: var(--white100);
}
</style>