import moment from "moment";

export const isDateInRange = (date: string, range: string | string[]) => {
    if(Array.isArray(range)) {
        const startDate = moment(range[0]);
        const endDate = moment(range[1]);
        return moment(date).isBetween(startDate, endDate, "day", "[]");
    } else {
        return moment(date).isSame(range, "day");
    }
}

export const compareArraysById = (array1: { id: number }[], array2: { id: number }[]): boolean => {
    const ids1 = array1.map(item => item.id);
    const ids2 = array2.map(item => item.id);
  
    const set1 = new Set(ids1);
    const set2 = new Set(ids2);
  
    if (set1.size !== set2.size) {
      return false;
    }

    for (const id of set1) {
      if (!set2.has(id)) {
        return false;
      }
    }
    return true;
  }
  