import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10b7dce4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "filter-container block mt-2 pb-4",
  style: {"overflow-x":"auto"}
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dates, (field) => {
      return (_openBlock(), _createBlock(_component_DateRangePicker, {
        key: field,
        class: "pill inline-block mb-1",
        start: $setup.startValues[field],
        end: $setup.endValues[field],
        placeholder: _ctx.$t(`${$setup.organisationPrefix}hzba.buttons.${field}Filter`),
        "onUpdate:range": ($event: any) => ($setup.dateFilterChanged(field, $event)),
        "onUpdate:single": ($event: any) => ($setup.dateFilterChanged(field, $event))
      }, null, 8, ["start", "end", "placeholder", "onUpdate:range", "onUpdate:single"]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filterOptions, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option.id,
        class: "inline-block mb-1"
      }, [
        (!option.onlyShowWhenOthersActive || $setup.resetButtonActive)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["pill", option.resetOtherFilters && $setup.resetButtonActive ? 'resetter' : ($props.selectedFilters && $props.selectedFilters.find(el => el.id === option.id) ? 'active' : '')]),
              onClick: () => $setup.toggleOption(option)
            }, _toDisplayString($setup.translatedHzbaStatus(option.id,$setup.t)), 11, _hoisted_2))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}